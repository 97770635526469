import { connect } from 'react-redux';
import { setVisibilityFilter, getDataFilter, clearData } from '../actions';
import CategoriesLink from '../components/CategoriesLink';

const mapStateToProps = (state, ownProps) => {
  return {
    active: ownProps.filter === state.visibilityFilter
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: () => {
      dispatch(setVisibilityFilter(ownProps.filter));
      dispatch(clearData());
      setTimeout(()=>{
        dispatch(getDataFilter(ownProps.filter));
      },10);
    }
  }
}

const CategoriesFilterLink = connect(mapStateToProps, mapDispatchToProps)(CategoriesLink);

export default CategoriesFilterLink;