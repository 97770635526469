import React from 'react';
import s from './CategoriesLink.module.css';

class CategoriesLink extends React.Component {

  render() {

    let active = (this.props.active) ? s.active : '';

    return (
      <li className={s.item}>
        <button
          onClick={e => {
            e.preventDefault()
            this.props.onClick()
          }}
          className={active}
        >
          {this.props.title}
        </button>
      </li>
    )
  };
}

export default CategoriesLink;