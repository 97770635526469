import React from 'react';
import $ from 'jquery';
import MainMenu from '../../components/MainMenu';
import IframeVimeo from '../../components/IframeVimeo';

//import CategoriesMenu from '../../components/CategoriesMenu';
import VisibleCategories from '../../containers/VisibleCategories';

import VisibleWorkList from '../../containers/VisibleWorkList';
import FooterMenu from '../../components/FooterMenu';
import s from './Home.module.css';

class Home extends React.Component {

    constructor(props) {
        super(props);
    
        this.refCategoriesMenu = React.createRef();
      }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll(event) {
        let posY = $('#categoriesMenu').offset().top - 80;

        if(window.scrollY >= posY) {
            $('#btn-reel').removeClass('active');
            $('#btn-work').addClass('active');
        } else {
            $('#btn-reel').addClass('active');
            $('#btn-work').removeClass('active');
        }
    }

    render() {
        return (
            <div>
                <MainMenu />
                <div className={s.main}>
                    <IframeVimeo id_video="427811249" />
                    <VisibleCategories />
                    <VisibleWorkList />
                    <FooterMenu />
                </div>
            </div>
        );
    }
}

export default Home;
