import React from 'react';
import $ from 'jquery';
import { Col  } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Preload from 'react-preload';
import s from './Work.module.css';
import load from '../../assets/img/loader.gif';

class Work extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      animatedImgEntered: false
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleImageLoadSuccess = this.handleImageLoadSuccess.bind(this);    
  }

  handleMouseEnter() {
    this.setState(state => ({
      animatedImgEntered: true
    }));
  }

  handleMouseLeave() {
    this.setState(state => ({
      animatedImgEntered: false
    }));
  }

  handleImageLoadSuccess() {
    $(`#image-work-${this.props.data.id}`).css('opacity', '1');
  }

  render() {

    var loadingIndicator = (<img className={s.loader} src={load}  alt="" />);
    var images = [`img/${this.props.data.cover_img}`,`img/${this.props.data.animated_img}`];

    return (
      <Col sm="6" md="4" lg="3" className={'p-0'}>
        <div className={s.boxImage}>
          <Preload
              loadingIndicator={loadingIndicator}
              images={images}
              autoResolveDelay={3000}
              onSuccess={this.handleImageLoadSuccess}
              resolveOnError={true}
              mountChildren={true}
          >
            <Link to={this.props.data.slug}
              onMouseOver={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              data-aos='fade-up'
              data-aos-delay={(100*this.props.index)}
            >
              <div className={s.title}>
                <h3>{this.props.data.advertiser}</h3>
                <p>{this.props.data.project}</p>
              </div>

              {
                !this.state.animatedImgEntered?
                  <img id={`image-work-${this.props.data.id}`} src={`img/${this.props.data.cover_img}`} className={'img-fluid'} alt="" />
                :
                  <img src={`img/${this.props.data.animated_img}`} className={'img-fluid'} alt="" />
              }
            </Link >
          </Preload>
        </div>
      </Col>
    );
  }
}

export default Work;
