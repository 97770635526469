import React from 'react';
//import s from './IframeVimeo.module.css';

class IframeVimeo extends React.Component {

  render() {

    let id_video = (this.props.id_video) ? this.props.id_video : '';

    return (
        <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
          <iframe 
            src={`https://player.vimeo.com/video/${id_video}?title=0&byline=0&portrait=0`}
            style={{position: 'absolute', top:0, left:0, width: '100%', height: '100%'}}
            frameBorder="0"
            title="Vídeo"
            allow="autoplay; fullscreen" allowFullScreen></iframe>
        </div>
    )
  };
}

export default IframeVimeo;