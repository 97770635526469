import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './pages/Home';
import Work from './pages/Work';

class App extends React.Component {

  componentDidMount(){
    AOS.init({
      duration : 800
    });
  }

  render() {
    return (
      <BrowserRouter basename="/manga2020">
          <Switch>
              <Route strict path="/" exact={true}  component={Home} />
              <Route strict path="/:slug" exact={true}  component={Work} />
          </Switch>
      </ BrowserRouter>
    );
  }
}

export default App;
