export const getDataFilter = filter => ({
  type: 'GET_DATA_FILTER',
  filter
});

export const getDataBySlug = (slug, data) => ({
  type: 'GET_DATA_BY_SLUG',
  slug,
  data
});

export const clearData = filter => ({
  type: 'CLEAR_DATA'
});

export const setVisibilityFilter = filter => ({
  type: 'SET_VISIBILITY_FILTER',
  filter
});

export const VisibilityFilters = {
  SHOW_ALL: 'all',
  SHOW_MOTION: 'motion',
  SHOW_DESIGN: 'design',
  SHOW_ILLUSTRATION: 'illustration',
  SHOW_CODE: 'code',
  SHOW_OTHER: 'other'
}
