import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import s from './FooterMenu.module.css';

class FooterMenu extends React.Component {

  render() {
    return (
      <Container fluid>
        <Row>
          <Col className="p-0">
            <ul className={s.footerMenu}>
              <li><a href="#" target="_blank">LINKEDIN</a></li>
              <li><a href="#" target="_blank">FACEBOOK</a></li>
              <li><a href="#" target="_blank">INSTAGRAM</a></li>
              <li><a href="#" target="_blank">TWITTER</a></li>
              <li><a href="#" target="_blank">YOUTUBE</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    );
  }
}
    
export default FooterMenu;