import React from 'react';
import { Link } from "react-router-dom";
import IframeVimeo from '../../components/IframeVimeo';
import s from './WorkVideo.module.css';


class WorkVideo extends React.Component {

  componentDidMount() {
    if(this.props.works.all) {
      this.props.getDataBySlug();
    } else {
      this.props.getData();
    }
  }

  render() {
    let filterData = this.props.works.filterData || this.props.works.all || [];

    return (
    <div className={s.main}>
        {filterData.map((work, index) => (
          <div key={index}>
            <div className={s.header}>
              <Link to="/">X CLOSE</Link>
            </div>
            <IframeVimeo id_video={work.id_video} />
            <div className={s.footer}>
              {`CLIENTE: ${work.client} - ANUNCIANTE: ${work.advertiser} PROJETO: ${work.project}`}
            </div>
          </div>
        ))}
    </div>
    );
  }
}

export default WorkVideo;
