import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Work from '../Work';
import s from './WorkList.module.css';

class WorkList extends React.Component {

  componentDidMount() {
    if(this.props.visibilityFilter !== 'all') {
      this.props.getDataFilter(this.props.visibilityFilter);
    } else {
      if(this.props.works.all) {
        this.props.getDataFilter(this.props.visibilityFilter);
      } else {
        this.props.getData();
      }
    }
  }

  render() {
    let filterData = this.props.works.filterData || this.props.works.all || [];

    return (
      <Container fluid>
        <Row className={s.containerWork}>
          {filterData.map((work, index) => (
            <Work key={index} data={work} index={index} />
          ))}
        </Row>
      </Container>
    );
  }
}

export default WorkList