import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CategoriesFilterLink from '../../containers/CategoriesFilterLink';
import { VisibilityFilters } from '../../actions';
import s from './CategoriesMenu.module.css';

class CategoriesMenu extends React.Component {

  componentDidMount() {
    this.props.getData();
  }

  render() {

    let motion = (this.props.categories.motion) ? this.props.categories.motion : false;
    let design = (this.props.categories.design) ? this.props.categories.design : false;
    let illustration = (this.props.categories.illustration) ? this.props.categories.illustration : false;
    let code = (this.props.categories.code) ? this.props.categories.code : false;
    let other = (this.props.categories.other) ? this.props.categories.other : false;
    let all = (this.props.categories.all) ? this.props.categories.all : false;

    return (
      <div className={s.categoriesMenu} id="categoriesMenu">
        <Container>
          <Row>
            <Col>
              <ul>
                { motion &&
                  <CategoriesFilterLink filter={VisibilityFilters.SHOW_MOTION} title="MOTION"></CategoriesFilterLink>
                }
                { design &&
                  <CategoriesFilterLink filter={VisibilityFilters.SHOW_DESIGN} title="DESIGN"></CategoriesFilterLink>
                }
                { illustration &&
                  <CategoriesFilterLink filter={VisibilityFilters.SHOW_ILLUSTRATION} title="ILLUSTRATION"></CategoriesFilterLink>
                }
                { code &&
                  <CategoriesFilterLink filter={VisibilityFilters.SHOW_CODE} title="CODE"></CategoriesFilterLink>
                }
                { other &&
                  <CategoriesFilterLink filter={VisibilityFilters.SHOW_OTHER} title="OTHER"></CategoriesFilterLink>
                }
                { all &&
                  <CategoriesFilterLink filter={VisibilityFilters.SHOW_ALL} title="ALL"></CategoriesFilterLink>
                }
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CategoriesMenu;