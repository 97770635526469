import { connect } from 'react-redux';
import CategoriesMenu from '../components/CategoriesMenu';
import axios from "axios";

const mapStateToProps = state => {
  return {
    categories: state.categories
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    async getData () {
        await axios.get("./works.json").then(response => {
          dispatch({
            type: "GET_CATEGORIES",
            categories: response.data.categories
          })
        })
    }
  }
}

const VisibleCategories = connect(mapStateToProps, mapDispatchToProps)(CategoriesMenu)

export default VisibleCategories;