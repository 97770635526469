import { connect } from 'react-redux';
import axios from "axios";
import { getDataBySlug } from '../actions';
import WorkVideo from '../components/WorkVideo';

const mapStateToProps = state => {
  return {
    works: state.works
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    async getData () {
        await axios.get("./works.json").then(response => {
          dispatch({
            type: "GET_DATA",
            data: response.data.data
          });
          dispatch(getDataBySlug(ownProps.slug));
        })
    },
    async getDataBySlug () {
      dispatch(getDataBySlug(ownProps.slug));
    }
  }
}


const SkugWorkList = connect(mapStateToProps, mapDispatchToProps)(WorkVideo)

export default SkugWorkList