import React from 'react';
import { Navbar, Nav  } from 'react-bootstrap';
import $ from 'jquery';
import logo from '../../assets/img/logo-manga.png';
import s from './MainMenu.module.css';
import './MainMenu.css';

class MainMenu extends React.Component {

  constructor(props) {
    super(props);
    this.handleClickReel = this.handleClickReel.bind(this);
    this.handleClickWork = this.handleClickWork.bind(this);
  }

  handleClickReel(e) {
    e.preventDefault();

    var mov = 0;
    $('html,body').animate({scrollTop: mov});
  }

  handleClickWork(e) {
    e.preventDefault();

    var mov = $('#categoriesMenu').offset().top - 75;
    $('html,body').animate({scrollTop: mov});
  }

  render() {
    return (
      <div id="nav-main-menu">
        <Navbar fixed="top" expand="md" className={s.navMainMenu}>
            <Navbar.Brand href="/" className={s.imgLogo}>
                <img
                src={logo}
                className="d-inline-block align-top"
                alt="Manga logo"
                data-aos='fade'
                />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="main-menu" />

            <Navbar.Collapse id="main-menu">
                <Nav className={`ml-auto ${s.navLink}`}>
                    <Nav.Link href="#" onClick={this.handleClickReel} id="btn-reel" className="active navLink">REEL</Nav.Link>
                    <Nav.Link href="#" onClick={this.handleClickWork} id="btn-work" className="navLink">WORK</Nav.Link>
                    <Nav.Link href="mailto:saura@studiomanga.com.br">CONTACT</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default MainMenu;