const works = (state = [], action) => {


    switch (action.type) {
      case 'GET_DATA':
        return {
          all: [
            ...action.data
          ],
          filterData: [
            ...action.data
          ]
        }
      case 'GET_DATA_FILTER':
        if(action.filter === 'all') {
          return {
            all: state.all,
            filterData: state.all
          }
        } else {
          return {
            all: state.all,
            filterData: state.all.filter(item => {
              return item.category === action.filter
            })
          }
        }
      case 'GET_DATA_BY_SLUG':
        return {
          all: state.all,
          filterData: state.all.filter(item => {
            return item.slug === action.slug
          })
        }
      case 'CLEAR_DATA':
        return {
          all: state.all,
          filterData: []
        }        
      default:
        return state
    }
  }
  
  export default works;