import { connect } from 'react-redux';
import WorkList from '../components/WorkList';
import { getDataFilter } from '../actions';
import axios from "axios";

const mapStateToProps = state => {
  return {
    works: state.works,
    visibilityFilter: state.visibilityFilter
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    async getData () {
        await axios.get("./works.json").then(response => {
          dispatch({
            type: "GET_DATA",
            data: response.data.data
          })
        })
    },
    getDataFilter: filter => {
      dispatch(getDataFilter(filter));
    }
  }
}

const VisibleWorkList = connect(mapStateToProps, mapDispatchToProps)(WorkList)

export default VisibleWorkList