import React from 'react';
import { useParams } from 'react-router-dom';
import SkugWorkList from '../../containers/SkugWorkList';
import s from './Work.module.css';

function Work () {

  let { slug } = useParams();

  return(
    <div className={s.main}>
      <SkugWorkList slug={slug} />
    </div>
  );
}

export default Work;
